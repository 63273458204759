import React from 'react';
import LandingLinks from '../landing-links/LandingLinks';
import Footer from '../footer/Footer';
import './landing-footer.css';
import PillHollow from "../pill-hollow/PillHollow";

const LandingFooter = () => {
  return (
    <section className='section-landing-footer'>
      <PillHollow content='Get in Touch' />
      <div className='container-landing-footer'>
        <LandingLinks />
        <div className='divider'>
        </div>
        <Footer />
      </div>
    </section>
  );
}

export default LandingFooter;